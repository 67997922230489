import * as React from "react"

import Layout from "../components/layout/layout"
import { DolnaMap } from "../components/dolna_map/dolna_map"
import { StaticQuery, graphql } from 'gatsby'

const pubsQuery = graphql`
query {
  allStrapiPub {
    edges {
      node {
        ...pubFields
      }
    }
  }
}
`

const IndexPage = () => (
  <Layout>
    <StaticQuery
      query={pubsQuery}
      render={data => (
        <DolnaMap pubs={data.allStrapiPub.edges} className="h-full min-h-full"/>
      )}
    />
  </Layout>
)


export default IndexPage
